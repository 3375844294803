<template>
  <table class="table-body" border="0">
    <thead>
      <tr>
        <td class="table-body__title">Название служения</td>
        <td class="table-body__title">Срок служения</td>
        <td class="table-body__title">Срок чистоты</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="table-body__subTitle" colspan="3">На группе</td>
      </tr>
      <tr v-for="item in serviceItems" :key="item.name">
        <td>{{ item.name }}</td>
        <td>{{ item.term }}</td>
        <td>{{ item.howTo }}</td>
      </tr>
      <tr>
        <td class="table-body__subTitle" colspan="3">
          В коммитете Б/у <i class="fa-solid fa-circle-info"></i>
        </td>
      </tr>
      <tr v-for="item in servicesBU" :key="item.name">
        <td>{{ item.name }}</td>
        <td>{{ item.term }}</td>
        <td>{{ item.howTo }}</td>
      </tr>
    </tbody>
  </table>
</template>


<script>
export default {
  data() {
    return {
      serviceItems: [
        {
          name: "Секретарь",
          term: "1 год",
          howTo: "не менее 1 (одного) года.",
        },
        { name: "Казначей", term: "1 год", howTo: "2 (два) года чистоты." },
        { name: "Литком", term: "1 год", howTo: "от 6 (шести) месяцев" },
        { name: "БУ(коммитет)", term: "1 год", howTo: "от 1 года" },
        { name: "Ведущий", term: "1 месяц", howTo: "от 3х месяцев" },
        { name: "Чайханщик", term: "1 месяц", howTo: "наличее 1 дня" },
        { name: "Уборщик", term: "1 месяц", howTo: "наличее 1 дня" },
        {
          name: "Горячая линия",
          term: "1 год",
          howTo: "наличее 1 (один) года",
        },
        { name: "Speaker Hunter", term: "1 год", howTo: "6 (шесть) месяцев" },
      ],
      servicesBU: [
        {
          name: "Председатель",
          term: "2 года",
          howTo: "от 2  лет",
        },
        {
          name: "Вице-председатель",
          term: "1 год",
          howTo: "от 1 года",
        },
        {
          name: "Координатор по стендам",
          term: "1 год",
          howTo: "6 месяцев",
        },
        {
          name: "Кассир",
          term: "1 год",
          howTo: "от 1  года",
        },
        {
          name: "Секретарь Б/У",
          term: "1 год",
          howTo: "от 6 месяцев",
        },
        {
          name: "Ответственный за литературу",
          term: "1 год",
          howTo: "от 6 месяцев",
        },
        {
          name: "Координатор",
          term: "1 год",
          howTo: "от 1 года",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
$main-color-blue: #1c7fe2;
.table-body {
  width: 100%;
  margin: 24px 0;

  &__title {
    font-size: 30px;
    line-height: 1;
    font-weight: 700;
    background: rgba(28, 127, 226, 0.3);
  }

  &__subTitle {
    text-align: center;
    font-size: 28px;
    line-height: 1.5;
    font-weight: 700;
    background: rgba(28, 127, 226, 0.3);
  }

  thead {
    border-radius: 5px 5px 0 0;
    position: sticky;
    top: 60px;
    background: #fff;
    z-index: 1;
    @media (max-width: 800px) {
      top: 54px;
    }
  }
  tr {
    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }

  td {
    padding: 12px 0;
    text-align: center;
    font-size: inherit;
  }
}
</style>