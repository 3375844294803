<template>
  <div>
    <h2 class="members-service__title members-service__closed" @click="toggle">
      Преамбулы группы
      <span :class="{ rotate: visible }">
        <i class="fas fa-chevron-right"></i>
      </span>
    </h2>
    <priambuls v-show="visible" />
  </div>
</template>

<script>
import Priambuls from "./Priambuls";
import toggleMixin from "@/toggleMixin";
export default {
  components: {
    Priambuls,
  },
  mixins: [toggleMixin],
};
</script>

<style lang="scss">
</style>