<template>
  <div class="priambuls">
    <div class="priambuls-body">
      <div class="priambuls-block" v-for="(img, idx) in imageList" :key="idx">
        <img :src="img.url" :alt="img.alt" />
      </div>
    </div>
  </div>
</template>


<script>
import fancyBox from "vue-fancybox";
export default {
  data() {
    return {
      priabulsItems: [{ url: "", title: "" }],
      imageList: [
        {
          alt: "Кто такой зависимы",
          url: require("../../assets/priambuls/preambul1.png"),
          width: 612,
          height: 792,
        },
        {
          alt: "Что такое программа АН",
          url: require("../../assets/priambuls/preambul2.png"),
          width: 612,
          height: 792,
        },
        {
          alt: "Почему мы здесь",
          url: require("../../assets/priambuls/preambul3.png"),
          width: 612,
          height: 792,
        },
        {
          alt: "Как это работает",
          url: require("../../assets/imgPriabuls/RU-how_page-0001.png"),
          width: 612,
          height: 792,
        },
        {
          alt: "Как это работает",
          url: require("../../assets/imgPriabuls/RU-how_page-0002.png"),
          width: 612,
          height: 792,
        },
        {
          alt: "Двеннацать традиций",
          url: require("../../assets/imgPriabuls/RU-trads_page-0001.png"),
          width: 612,
          height: 792,
        },
        {
          alt: "Двеннацать традиций",
          url: require("../../assets/imgPriabuls/RU-trads_page-0002.png"),
          width: 612,
          height: 792,
        },
        {
          alt: "Мы действительно выздоравливаем",
          url: require("../../assets/priambuls/preambul6.png"),
          width: 612,
          height: 792,
        },
        {
          alt: "Что такое служение в АН",
          url: require("../../assets/priambuls/preambul8.jpeg"),
          width: 612,
          height: 792,
        },
        {
          alt: "Только сегодня",
          url: require("../../assets/priambuls/preambul7.png"),
          width: 612,
          height: 792,
        },
      ],
    };
  },
  methods: {
    open(e) {
      fancyBox(e.target, this.imageList);
    },
  },
};
</script>


<style lang="scss">
.priambuls-body {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(612px, 1fr));
  gap: 12px;
  justify-content: space-between;
  margin: 1rem 0;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }

  img {
    // max-width: 612px;
    max-width: 100%;
    object-fit: cover;
    @media screen and(max-width: 600px) {
      max-width: 100%;
    }
  }
}
</style>