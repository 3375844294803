<template>
  <div class="members">
    <h1 class="header-title">Приветствуем тебя в нашем сообществе</h1>
    <div class="members-text">
      <p class="text-block">
        Ничто не может заменить живое общение, и поэтому чтобы быстрее понять
        кто мы и как можем тебе помочь — мы советуем прямо сегодня посетить одно
        из собраний наших групп, которые проходят в город Смоленск
      </p>
    </div>
    <div class="members-service">
      <servis-group-main />
    </div>
    <div class="members-priambuls">
      <priambuls-main />
    </div>
    <div class="members-priambuls">
      <podcomitet-main />
    </div>
  </div>
</template>

<script>
import ServisGroupMain from "@/components/Members/ServisGroupMain";
import PriambulsMain from "@/components/Members/PriambulsMain";
import PodcomitetMain from "@/components/Members/PodcomitetMain";

export default {
  created() {
    document.title = "Участнику";
  },

  data() {
    return {
      membersVisible: 0,
    };
  },
  components: {
    ServisGroupMain,
    PriambulsMain,
    PodcomitetMain,
  },

  methods: {
    visibleBlock() {
      membersVisible: null;
    },
  },
};
</script>

<style lang="scss">
.members-service,
.members-priambuls {
  margin: 12px;
  span {
    font-size: 18px;
    margin-left: 12px;
    transition: all 0.7s;
  }

  &__title {
    font-size: 30px;
    margin: 0px 0px 1rem;
    line-height: 1;
    @media (max-width: 800px) {
      font-size: 24px;
    }
  }

  &__closed {
    background-color: #1c7fe2;
    color: #fff;
    padding: 12px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.hide {
  display: none;
}

.rotate {
  i {
    transform: rotate(90deg);
  }
}
</style>