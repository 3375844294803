<template>
  <div>
    <h2 class="members-service__title members-service__closed" @click="toggle">
      Подкомитеты
      <span :class="{ rotate: visible }">
        <i class="fas fa-chevron-right"></i>
      </span>
    </h2>
    <div class="podcomitet-block" v-show="visible">
      <podcommitet-bu />
      <podcomitet-pso />
      <podcomitet-info />
      <podcomitet-lit />
    </div>
  </div>
</template>

<script>
import PodcommitetBu from "./PodcommitetBu";
import PodcomitetPso from "./PodcomitetPso";
import PodcomitetInfo from "./PodcomitetInfo";
import PodcomitetLit from "./PodcomitetLit";
import toggleMixin from "@/toggleMixin";

export default {
  components: {
    PodcommitetBu,
    PodcomitetPso,
    PodcomitetInfo,
    PodcomitetLit,
  },
  mixins: [toggleMixin],
};
</script>

<style lang="scss">
</style>